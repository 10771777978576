<script>
export default {
  metaInfo: {
    title: "Service Log",
  }
};
</script>

<script setup>
import DynamicServiceLogView from '@/components/dynamic/serviceLogView.vue';
</script>

<template>
  <DynamicServiceLogView/>
</template>
